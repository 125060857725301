var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "agreement-confirm-btn",
      style: { background: _vm.styleConfig.bgColor },
    },
    [
      _c(
        "button",
        {
          style: {
            background: _vm.disabled ? "#ababab" : _vm.styleConfig.btnColor,
          },
          attrs: { disabled: _vm.disabled },
          on: { click: _vm.onConfirm },
        },
        [
          _vm._v(
            "\n        " +
              _vm._s(_vm.isPlanB ? "Setujui Perjanjian" : "Setuju") +
              "\n        "
          ),
          _vm.showTime
            ? _c("span", [
                _vm._v("(" + _vm._s(_vm.sec) + " " + _vm._s("detik") + ")"),
              ])
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }