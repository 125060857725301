var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "signBtn", staticClass: "agreement-sign-btn", style: _vm.btnStyle },
    [
      (_vm.isDebt || _vm.isAutoDebt) &&
      _vm.titleList.length > 1 &&
      _vm.isInvestaja
        ? _c(
            "div",
            { staticClass: "protocol" },
            [
              _c(
                "div",
                { staticClass: "title-wrap", on: { click: _vm.onChange } },
                [
                  _c("div", { staticClass: "title-left" }, [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.getI18n("allAgreement")) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "title-right" }, [
                    _c("span", { staticClass: "text" }, [
                      _vm._v(
                        _vm._s(
                          _vm.getI18n("totalAgreement", [_vm.titleList.length])
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "arrow" }),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.isOpen
                ? _vm._l(_vm.titleList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "list-item",
                        class: { active: _vm.nowIndex === index },
                        on: {
                          click: function ($event) {
                            return _vm.onAgreementChange(index)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                " + _vm._s(item) + "\n            "
                        ),
                      ]
                    )
                  })
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.signBtnVisible
        ? [
            _c(
              "button",
              {
                directives: [
                  {
                    name: "yqg-event",
                    rawName: "v-yqg-event",
                    value: _vm.BusinessEvents.AgreementSignButton,
                    expression: "BusinessEvents.AgreementSignButton",
                  },
                ],
                staticClass: "sign-btn",
                style: { background: _vm.styleConfig.btnColor },
                on: { click: _vm.showSignPad },
              },
              [_vm._v("\n            " + _vm._s(_vm.btnText) + "\n        ")]
            ),
            _vm._v(" "),
            _vm.isDebt && _vm.timeCount
              ? _c("div", { staticClass: "desc" }, [
                  _c("span", [_vm._v(_vm._s(_vm.getI18n("newTimeDesc")))]),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "time-font",
                      style: { color: _vm.styleConfig.btnColor },
                    },
                    [_vm._v(_vm._s(_vm._f("timeFormat")(_vm.timeCount)))]
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.getI18n("newDesc")))]),
                ])
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }