<!-- @Author: xiaodongyu -->
<!-- @Date: 2019-12-06 18:24:09 -->
<!-- @Last Modified by: xiaodongyu -->
<!-- @Last Modified time: 2024-03-15 14:45:24 -->

<template>
    <div
        class="agreement-confirm-btn"
        :style="{background: styleConfig.bgColor}"
    >
        <button
            :style="{background: disabled ? '#ababab' : styleConfig.btnColor}"
            :disabled="disabled"
            @click="onConfirm"
        >
            {{ isPlanB ? 'Setujui Perjanjian' : 'Setuju' }}
            <span v-if="showTime">({{ sec }} {{ 'detik' }})</span>
        </button>
    </div>
</template>

<script>
import {mapState} from 'vuex';

import {uploadWebBizEvent} from '@shared/client/util/web-biz-event-sdk';

import WebviewInterface from 'ssr-common/util/webview/yqg-webview-interface';

const StyleConfig = {
    easycash: {
        bgColor: '#f5fbfb',
        btnColor: '#03DA8B'
    },
    investaja: {
        bgColor: '#f5f6fb',
        btnColor: '#143be6'
    },
    rupiahmart: {
        bgColor: '#f5fbfb',
        btnColor: '#03DA8B'
    }
};

export default {
    name: 'AgreementConfirmBtn',

    props: {
        isBottom: {
            type: Boolean,
            default: false
        },

        atBottom: {
            type: Boolean,
            default: false
        },

        isPlanB: {
            type: Boolean,
            default: false
        }
    },

    data() {
        const sec = this.$app.isIOS ? 3 : this.$route.query?.clickWaitingTime ? Math.floor(this.$route.query?.clickWaitingTime / 1000) : 3;

        return {
            sec: sec,
            timmer: null
        };
    },

    computed: {
        ...mapState('baseData', [
            'brand'
        ]),

        styleConfig() {
            return StyleConfig[this.brand];
        },

        disabled() {
            const {isBottom, brand, atBottom, sec} = this;
            const {clickWaitingTime} = this.$route.query || {};

            if (this.$app.isIOS) {
                return (brand === 'rupiahmart' || isBottom) ? !atBottom : sec > 0;
            } else {
                if (!clickWaitingTime) return false;

                return (brand === 'rupiahmart' || isBottom) ? !atBottom : sec > 0;
            }
        },

        showTime() {
            const {disabled, brand, isBottom} = this;
            const {clickWaitingTime} = this.$route.query || {};

            if (this.$app.isIOS) {
                if (brand === 'rupiahmart' || isBottom) {
                    return false;
                }

                return disabled;
            } else {
                if (!clickWaitingTime) return false;
                if (brand === 'rupiahmart' || isBottom) {
                    return false;
                }

                return disabled;
            }
        }
    },

    mounted() {
        this.timmer = setInterval(() => {
            if (this.sec > 0) {
                this.sec -= 1;
            } else {
                clearInterval(this.timmer);
            }
        }, 1000);
    },

    beforeDestroy() {
        clearInterval(this.timmer);
    },

    methods: {
        onConfirm() {
            const {$route: {query: {purpose}}} = this;
            uploadWebBizEvent(`success${this.$route.fullPath}`);
            WebviewInterface.confirmAgreement(purpose);
            WebviewInterface.closeWebPage();
        },

    }
};
</script>

<style lang="scss" scoped>
.agreement-confirm-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.2rem;

    button {
        width: 100%;
        margin: 10px 0;
        height: 60px;
        border-radius: 8px;
        border: none;
        outline: none;
        font-size: 18px;
        color: #fff;
    }
}
</style>
