var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "div",
        { staticClass: "agreement-download-btn", on: { click: _vm.download } },
        [
          _c(
            "svg",
            {
              attrs: {
                viewBox: "0 0 15 17",
                version: "1.1",
                xmlns: "http://www.w3.org/2000/svg",
                "xmlns:xlink": "http://www.w3.org/1999/xlink",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "0003_home",
                    stroke: "none",
                    "stroke-width": "1",
                    fill: "none",
                    "fill-rule": "evenodd",
                  },
                },
                [
                  _c(
                    "g",
                    {
                      attrs: {
                        transform: "translate(-319.000000, -580.000000)",
                        fill: _vm.primaryColor,
                      },
                    },
                    [
                      _c(
                        "g",
                        {
                          attrs: {
                            transform: "translate(302.000000, 565.000000)",
                          },
                        },
                        [
                          _c(
                            "g",
                            {
                              attrs: {
                                id: "icon/back",
                                transform:
                                  "translate(24.000000, 24.000000) rotate(-90.000000) translate(-24.000000, -24.000000) translate(12.000000, 12.000000)",
                              },
                            },
                            [
                              _c("path", {
                                attrs: {
                                  id: "arrow-left---anticon",
                                  d: "M20.0076373,11.4939024 C20.286543,11.4939024 20.5250771,11.6006087 20.723247,11.8140244 C20.9214168,12.0274401 21.0131607,12.278962 20.9984814,12.5685976 C20.9838022,12.8277452 20.881049,13.0487796 20.6902188,13.2317073 C20.4993886,13.4146351 20.2792032,13.5060976 20.0296561,13.5060976 L7.41290754,13.5060976 L12.1689593,18.2621951 C12.3597895,18.4603668 12.458873,18.696645 12.4662126,18.9710366 C12.4735522,19.2454282 12.3818083,19.4817063 12.1909781,19.679878 C12.0001479,19.8932938 11.7652835,20 11.4863778,20 C11.2368307,20 11.0166453,19.9085375 10.8258151,19.7256098 L4.30826262,13.2088415 C4.10275318,13.0106697 4,12.7705807 4,12.4885671 C4,12.2065535 4.10275318,11.9664644 4.30826262,11.7682927 L10.7817776,5.27439024 C10.987287,5.0914625 11.2221514,5 11.4863778,5 C11.7506043,5 11.9744594,5.10289531 12.15795,5.30868902 C12.3414405,5.51448274 12.4331844,5.7545718 12.4331844,6.02896341 C12.4331844,6.30335503 12.3304313,6.53963316 12.1249218,6.73780488 L7.36887003,11.4939024 L20.0076373,11.4939024 Z",
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }