<!-- @Author: chengyuzhang -->
<!-- @Date: 2021-3-9 11:14:24 -->
<!-- @Last Modified by: xiaodongyu -->
<!-- @Last Modified time: 2024-03-15 15:22:33 -->

<template>
    <div
        ref="signBtn"
        class="agreement-sign-btn"
        :style="btnStyle"
    >
        <div
            v-if="(isDebt || isAutoDebt) && titleList.length > 1 && isInvestaja"
            class="protocol"
        >
            <div
                class="title-wrap"
                @click="onChange"
            >
                <div class="title-left">
                    {{ getI18n('allAgreement') }}
                </div>
                <div class="title-right">
                    <span class="text">{{ getI18n('totalAgreement', [titleList.length]) }}</span>
                    <div class="arrow" />
                </div>
            </div>
            <template v-if="isOpen">
                <div
                    v-for="(item, index) in titleList"
                    :key="index"
                    class="list-item"
                    :class="{active: nowIndex === index}"
                    @click="onAgreementChange(index)"
                >
                    {{ item }}
                </div>
            </template>
        </div>
        <template v-if="signBtnVisible">
            <button
                v-yqg-event="BusinessEvents.AgreementSignButton"
                :style="{background: styleConfig.btnColor}"
                class="sign-btn"
                @click="showSignPad"
            >
                {{ btnText }}
            </button>
            <div
                v-if="isDebt && timeCount"
                class="desc"
            >
                <span>{{ getI18n('newTimeDesc') }}</span>
                <span
                    class="time-font"
                    :style="{color: styleConfig.btnColor}"
                >{{ timeCount | timeFormat }}</span>
                <span>{{ getI18n('newDesc') }}</span>
            </div>
        </template>
    </div>
</template>

<script>
import {startTransaction} from '@sentry/vue';
import {mapState} from 'vuex';

import {uploadWebBizEvent} from '@shared/client/util/web-biz-event-sdk';

import {isEasyCashNative} from 'ssr-common/util/webview/ua';
import WebviewInterface from 'ssr-common/util/webview/yqg-webview-interface';

import BusinessEvents from 'easycash/common/constant/business-events';
import parseUa from 'easycash/common/mixin/parse-ua';
import Api from 'easycash/common/resource/loan/index';

const StyleConfig = {
    easycash: {
        bgColor: '#f5fbfb',
        btnColor: '#03DA8B'
    },
    investaja: {
        bgColor: '#f5f6fb',
        btnColor: '#143be6'
    }
};

export default {
    name: 'AgreementSignBtn',

    filters: {
        timeFormat(val) {
            const hh = parseInt(val / (60 * 60), 10)
                .toString()
                .padStart(2, 0);
            const mm = parseInt((val / 60) % 60, 10)
                .toString()
                .padStart(2, 0);
            const ss = parseInt(val % 60, 10)
                .toString()
                .padStart(2, 0);

            return `${hh}:${mm}:${ss}`;
        }
    },

    inject: {
        uploadClickEvent: {
            default: async () => ({})
        }
    },

    props: {
        auto: {
            type: Boolean,
            default: true
        },

        isDebt: {
            type: Boolean,
            default: false
        },
        isAutoDebt: {
            type: Boolean,
            default: false
        },
        nowIndex: {
            type: Number,
            default: 0
        },

        titleList: {
            type: Array,
            default: () => []
        },

        signBtnVisible: {
            type: Boolean,
            default: false
        }
    },

    mixins: [parseUa()],

    data() {
        const timeCount = this.getInitTime();

        return {
            BusinessEvents,
            isOpen: false,
            timeCount,
            timeout: null,
            experimentType: '',
        };
    },

    computed: {
        ...mapState('baseData', ['brand']),

        isInvestaja() {
            return this.brand === 'investaja';
        },

        isOnlyEashCash() {
            return isEasyCashNative(this.ua);
        },

        styleConfig() {
            return StyleConfig[this.brand] ?? {};
        },
        btnText() {
            return this.$t('webview.agreement.newSign');
        },

        btnStyle() {
            const {isDebt, styleConfig, titleList, signBtnVisible} = this;
            if (isDebt && titleList?.length > 1) {
                return {
                    background: styleConfig.bgColor,
                    paddingTop: '0.1rem',
                    paddingBottom: '0.1rem'
                };
            }

            if (!signBtnVisible) {
                return {
                    display: 'none'
                };
            }

            return {
                background: styleConfig.bgColor
            };
        }
    },

    mounted() {
        const {timeExpired} = this.$route.query;
        if (this.auto) this.showSignPad();
        if (this.isDebt) this.emitHeight();
        if (this.isDebt && timeExpired) {
            this.countDown();
            document.addEventListener('visibilitychange', this.initTimeCount);
        }

        if (this.isOnlyEashCash && !this.isInvestaja) {
            this.getAbStatus();
        }
    },

    beforeDestroy() {
        const {timeExpired} = this.$route.query;
        if (this.timeout) clearTimeout(this.timeout);
        if (this.isDebt && timeExpired) {
            document.removeEventListener('visibilitychange', this.initTimeCount);
        }
    },

    methods: {
        initTimeCount() {
            if (document.visibilityState === 'visible') {
                if (this.timeout) {
                    clearTimeout(this.timeout);
                    this.timeout = null;
                }

                this.timeCount = this.getInitTime();
                this.countDown();
            }
        },

        async getAbStatus() {
            const {businessType, checkType} = this.$route.query || {};

            if (businessType === 'ORDER_CHECK' && this.isOnlyEashCash) {
                this.experimentType = checkType;
                this.uploadClickEvent('EasyCashstaticAgreementPageEnter', {
                    type: checkType
                });
            }
        },

        getInitTime() {
            const {timeExpired} = this.$route.query;

            return Math.floor((timeExpired - Date.now()) / 1000);
        },

        async countDown() {
            if (this.timeCount < 0) {
                this.timeCount = 0;
                await this.uploadClickEvent('AgreementTimeoutClose');
                WebviewInterface.closeWebPage();

                return;
            }

            this.timeout = setTimeout(() => {
                this.timeCount -= 1;
                this.countDown();
            }, 1000);
        },

        getI18n(text, params) {
            return this.$t(`webview.agreement.${text}`, params);
        },

        emitHeight() {
            const btnHeight = this.$refs.signBtn?.offsetHeight ?? 0;
            this.$emit('btnMount', btnHeight);
        },

        async showSignPad() {
            if (this.experimentType === 'CLICK_HAND_WRITTEN_SIGNATURE' && this.isOnlyEashCash) {
                try {
                    await this.handleEasyCashSignature();
                } catch (error) {
                    // ignore
                }

                this.uploadClickEvent('EasyCashstaticAgreementBtnClick', {
                    type: this.experimentType
                });

                return;
            }

            const params = JSON.stringify(this.$route.query);
            WebviewInterface.showSignPad(params);
            uploadWebBizEvent(`show-sign-pad${this.$route.fullPath}`);
            this.uploadClickEvent('EasyCashstaticAgreementBtnClick', {
                type: 'old'
            });
        },

        async handleEasyCashSignature() {
            const {businessId, businessType} = this.$route.query || {};
            await Api.signUp({}, {params: {businessId, businessType}});
            await this.startPollingForSignature({businessId, businessType});
        },

        async startPollingForSignature(paramsdata, attempt = 0) {
            try {
                const {data} = await Api.getAbExperiment({...paramsdata, checkTypeGroup: 'SIGNATURE'});
                const newcheckTypeStatus = data?.body?.checkTypeStatus || '';

                if (newcheckTypeStatus === 'SUCCESS' || attempt === 10) {
                    this.signInPerfLog();
                    uploadWebBizEvent(`success${this.$route.fullPath}`);
                    WebviewInterface.confirmAgreement('LOAN_AGREEMENT');
                    WebviewInterface.closeWebPage();
                } else {
                    setTimeout(() => this.startPollingForSignature(paramsdata, attempt + 1), 800);
                }
            } catch (error) {
                WebviewInterface.openHomePage();
            }
        },

        async signInPerfLog() {
            try {
                const transaction = startTransaction({
                    name: 'static-agreement-sign',
                    sampled: true
                });
                await Promise.resolve();
                transaction.finish();
             } catch (error) {
                //ignore
             }
        },

        onAgreementChange(index) {
            this.$emit('agreementChange', index);
        },

        onChange() {
            this.isOpen = !this.isOpen;
            this.$nextTick(() => {
                this.emitHeight();
            });
        }
    }
};
</script>

<style lang="scss" scoped>
@font-face {
    font-family: "Roboto";
    src: url("~easycash/common/style/font-face/Roboto.ttf");
}

.agreement-sign-btn {
    box-sizing: border-box;
    width: calc(100% - 40px);
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 0.2rem 0;
    overflow: hidden;
    border: none;

    .protocol {
        box-sizing: border-box;
        padding: 0.15rem 0.2rem 0.1rem;
        background: #fff;
        box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.06);
        border-radius: 8px;
        margin-bottom: 0.1rem;

        .title-wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #000;
            line-height: 16px;

            .title-right {
                display: flex;
                align-items: center;

                .text {
                    margin-right: 0.05rem;
                }

                .arrow {
                    margin-bottom: 0.04rem;
                    width: 0.05rem;
                    height: 0.05rem;
                    border-top: 2px solid #dedede;
                    border-right: 2px solid #dedede;
                    transform: rotate(135deg);
                    transition: transform 0.3s ease-in-out;
                }
            }
        }

        .list-item {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999;
            line-height: 16px;
            margin-top: 0.1rem;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #999;
            line-height: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .active {
            font-weight: 900;
            color: #000;
        }
    }

    .desc {
        text-align: center;
        margin-top: 0.1rem;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999;
        line-height: 14px;

        .time-font {
            font-family: "Roboto";
        }
    }

    .sign-btn {
        outline: 0;
        border: 0;
        width: 100%;
        text-align: center;
        padding: 0.18rem 0;
        border-radius: 8px;
        font-size: 18px;
        font-family: Helvetica-Bold, Helvetica;
        font-weight: bold;
        color: #fff;
        line-height: 25px;
    }
}
</style>
